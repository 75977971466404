<template>
  <div>
    <h2>管理者ページ</h2>
    <div>
      <router-link to="/admin/stripe">支払い確認</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Admin'
}
</script>
